/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
'use strict'; // eslint-disable-line

var envProperties = {
    // eslint-disable-line
    current: null,
    version: '14.0.6',
    dev: {
        base: 'http://localhost:4200',
        serverContext: '/spid-webapp/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.preprod.decathlon.net',
        GAAccount: 'UA-111508656-1',
        beamerAccount: '',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'preprod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.preprod.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.stg.decathlon.net/',
        spoBaseUrl: 'https://spo.preprod.decathlon.net/',
        apiKey: '45305342-cc0b-40bf-a89c-e446b8b91001',
        localeLabUrl: 'https://spid-labs-preprod.decathlon.net'
    },
    preprod: {
        base: 'https://api.preprod.decathlon.net/spid',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.preprod.decathlon.net',
        GAAccount: 'UA-112504779-1',
        beamerAccount: '',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'preprod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.preprod.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.preprod.decathlon.net/',
        spoBaseUrl: 'https://spo.preprod.decathlon.net/',
        apiKey: '45305342-cc0b-40bf-a89c-e446b8b91001',
        localeLabUrl: 'https://spid-labs-preprod.decathlon.net'
    },
    production: {
        base: 'https://api.decathlon.net/spid',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.decathlon.net',
        GAAccount: 'UA-112543234-1',
        beamerAccount: 'OBPmBKlR42153',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'prod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.decathlon.net/',
        spoBaseUrl: 'https://spo.decathlon.net/',
        apiKey: 'cd0b4714-3983-48d0-b38a-c6cd349bea61'
    },
    internalProd: {
        base: 'https://api.decathlon.net/spid',
        serverContext: '/web',
        serverApiContext: '',
        contentsURL: 'https://contents-spid.decathlon.net',
        GAAccount: 'UA-112543234-1',
        beamerAccount: 'OBPmBKlR42153',
        offerdatasThirdId: '0480048300483',
        wediaEnvironment: 'prod',
        brightCoveAccountId: '3415345270001',
        brightCovePlayerId: 'rJxNjfhX',
        transdiagBaseUrl: 'https://transdiag.subsidia.org',
        productBriefBaseUrl: 'https://product-brief.decathlon.net/',
        spoBaseUrl: 'https://spo.decathlon.net/',
        apiKey: 'cd0b4714-3983-48d0-b38a-c6cd349bea61',
        localeLabUrl: 'https://spid-labs.decathlon.net'
    },
    urls: {
        authUser: 'auth/me',
        authorizeUrl: 'oauth2/authorization/spid',
        authTokensUrl: 'auth/tokens', // login/oauth2/code/spid
        refreshTokensUrl: 'auth/tokens/refresh',
        brandLdap: 'user/brands',
        brightcoveUrl: '//players.brightcove.net/{account_id}/{Player_id}_default/index.min.js',
        tree: 'treeView',
        countryContext: 'country',
        translation: 'translations',
        massActionsContext: 'massiveActions',
        parametersContext: 'parameters',
        fluxContext: 'flux',
        rightsManagementLink: '/fwkbo/index.html',
        logout: '/saml/logout',
        notationUnits: 'notations',
        zd: 'zd',
        offerdatas: 'offerdata',
        videoAvailableLanguagesURL: 'repository/availableLanguages',
        seasonsContext: 'seasons',
        brandContext: 'brands',
        modelBrand: 'models/{modelCode}/brand',
        mediasContext: 'medias',
        modelsContext: 'models',
        modelSeasonsContext: 'modelSeasons',
        superModelsContext: 'superModels',
        superModelEditProductNature: 'superModels/{smCode}/productNature',
        productCardContext: 'productCard',
        superModelSeasonsContext: 'superModelSeasons',
        superModelSeasonEvents: 'superModelSeasonEvents',
        superModelCreateVersion: 'superModelCreateVersion',
        userBenefitContext: 'userBenefits',
        translationJobsContext: 'translationJobs',
        urlRightToLeftLocale: 'country/rightToLeft',
        translationMappingsContext: 'translationMappings',
        sseContext: 'sse',
        cacheResetSseUrl: '/cache',
        brandUniverse: 'branduniverses',
        transdiagTranslationState:
            '/transdiag/app/#/cspid_infos/model_code_date_sale',
        toursContext: 'tours',
        propagationContext: 'propagation',
        versionsContext: 'versions',
        productBriefReferenceEndpoint: 'reference/',
        spoUpdateSmEndpoint: 'update-sm-ga',
        timeline: 'timeline',
    },
};

var wediaEnvironments = {
    // eslint-disable-line
    preprod: {
        cartContent: 'https://www.mediadecathlon.com/api.jspz',
        cdnBaseUrl: 'https://preprod2.mediadecathlon.com'
    },
    prod: {
        cartContent: 'https://www.mediadecathlon.com/api.jspz',
        cdnBaseUrl: 'https://contents.mediadecathlon.com'
    },
};
