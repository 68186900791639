(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('parameterService', parameterService);

    parameterService.$inject = ['axiosService', 'envService'];

    function parameterService(axiosService, envService) {

        return {
            getParameter: function (context, name) {
                return new Promise((resolve, reject) => {
                    axiosService.get(
                        envService.getParametersURL(),
                        {
                            params: { context, name },
                            withCredentials: true,
                            headers: {
                                'Accept': 'application/json;charset=UTF-8'
                            }
                        }).then(function (response) {
                        if (response.data) {
                            return resolve(response.data.value);
                        }
                    }).catch(reject);
                });
            },
            saveParameter: function (parameter) {
                return new Promise((resolve, reject) => {
                    axiosService.post(
                        envService.getParametersURL() + '?context=' + parameter.context + '&name=' + parameter.name,
                        parameter,
                        {
                            withCredentials: true,
                            headers: {
                                'Accept': 'application/json;charset=UTF-8'
                            }
                        })
                        .then(function (response) {
                            return resolve(response.data);
                        }).catch(reject);
                });
            }
        };
    }
})();
