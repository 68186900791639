(function () {
    'use strict';

    angular.module('spidwebApp').factory('userSettings', userSettings);

    userSettings.$inject = [
        '$rootScope',
        '$timeout',
        '$stateParams',
        '$injector',
        'tmhDynamicLocale',
        '$translate',
        '$window',
        '$analytics',
        'envService',
        'webStorage',
        'countriesService',
        '$uibModal',
        'amMoment',
        'axiosService',
        'authService',
    ];

    function userSettings(
        $rootScope,
        $timeout,
        $stateParams,
        $injector,
        tmhDynamicLocale,
        $translate,
        $window,
        $analytics,
        envService,
        webStorage,
        countriesService,
        $uibModal,
        amMoment,
        axiosService
    ) {

        // Public API here
        var user = null;
        return {
            getUserProperties: function () {
                var that = this;
                return axiosService
                    .get(`${envService.getUserContext()}/${this.getUser().uid}/userProperties`)
                    .then(function (response) {
                        return response.data;
                    })
                    .then(function (userProperties) {
                        var properties = {};

                        _(userProperties).each(function (userProperty) {
                            properties[userProperty.propertyName] =
                                userProperty.propertyValue;
                        });

                        return (that.getUser().userProperties = properties);
                    });
            },
            addUserAdditionalProperty: function (propertyName, propertyValue) {
                var that = this;

                return axiosService
                    .put(`${envService.getUserContext()}/${this.getUser().uid}/userProperties`,
                        {
                            propertyName,
                            propertyValue
                        }
                    )
                    .then(function () {
                        that.getUser().userProperties[propertyName] =
                            propertyValue;
                        return Promise.resolve(that.getUser);
                    });
            },
            deleteUserAdditionalProperty: function (propertyName) {
                var that = this;

                return axiosService
                    .delete(`${envService.getUserContext()}/${this.getUser().uid}/userProperties/${propertyName}`)
                    .then(function () {
                        delete that.getUser().userProperties[propertyName];
                    });
            },
            switchIhmLocale: function (locale) {
                if (!locale) {
                    locale = this.getSelectedIhmLocale();
                }

                $rootScope.ihmLanguage = locale;
                this.setSelectedIhmLocale(locale);
                tmhDynamicLocale.set(this.getSelectedIhmLanguage());

                var language = locale.split('_')[0];
                moment.locale(language);
                amMoment.changeLocale(language);

                axiosService.instance.defaults.headers.common['Accept-Language'] = locale.replace('_', '-');

                $translate.use(locale);

                $rootScope.$broadcast('ihmLanguageChanged');
            },
            getSelectedWorkingLocale: function () {
                var selectedLocale = webStorage.local.get('selectedLocale');
                if (!selectedLocale) {
                    if (
                        user &&
                        user.user &&
                        user.user.languages &&
                        user.user.languages.length > 0 &&
                        user.user.languages[0].contains('_')
                    ) {
                        selectedLocale = user.user.languages[0];
                    } else {
                        // WTF là !!!
                        selectedLocale =
                            user &&
                            user.user &&
                            user.user.languages[0] + '_' + user.user.country;
                    }
                }
                if (selectedLocale === 'en_CN') {
                    selectedLocale = 'zh_CN';
                }
                return selectedLocale;
            },

            getSelectedWorkingCountry: function () {
                return this.getSelectedWorkingLocale().split('_')[1];
            },

            getSelectedWorkingLanguage: function () {
                return this.getSelectedWorkingLocale().split('_')[0];
            },

            setSelectedWorkingLocale: function (locale) {
                webStorage.local.set('selectedLocale', locale);
            },

            checkWorkingLocale: function () {
                let currentUserLocale = this.getSelectedWorkingLocale();
                let currentUserIhm = this.getSelectedIhmLanguage();

                countriesService
                    .getLocales(currentUserIhm)
                    .then(function (locales) {
                        let localeExists =
                            locales.find(function (locale) {
                                return locale.locale === currentUserLocale;
                            }) != null;

                        let workingLocaleModal;

                        if (!localeExists) {
                            if (!workingLocaleModal) {
                                workingLocaleModal = $uibModal.open({
                                    templateUrl:
                                        'dialogs/badLocale/chooseYourLocale.html',
                                    controller: 'ChooseYourLocaleController',
                                    backdrop: 'static',
                                    resolve: {
                                        locales: function () {
                                            return countriesService.getLocales(
                                                currentUserIhm
                                            );
                                        },
                                    },
                                });
                                workingLocaleModal.closed.then(function () {
                                    workingLocaleModal = null;
                                });
                            }
                        }
                    });
            },

            getLastSearch: function () {
                var lastSearchModels = webStorage.local.get('lastSearch');
                if (!lastSearchModels) {
                    lastSearchModels = [];
                }
                return lastSearchModels;
            },

            setLastSearch: function (code) {
                if (code) {
                    var lastSearchModels = this.getLastSearch();
                    var existingSearch = _(lastSearchModels)
                        .chain()
                        .filter(function (myEntr) {
                            return myEntr.code === code;
                        })
                        .first()
                        .value();
                    if (!existingSearch) {
                        lastSearchModels.push({
                            code: code,
                            searchDate: moment().valueOf(),
                        });
                    } else {
                        existingSearch.searchDate = moment().valueOf();
                    }
                    webStorage.local.set(
                        'lastSearch',
                        _(lastSearchModels)
                            .sortBy(function (searchedModel) {
                                return -searchedModel.searchDate;
                            })
                            .slice(0, 10)
                    );
                }
            },

            getSelectedIhmLocale: function () {
                var selectedIhmLanguage = webStorage.local.get('ihmLanguage');
                if (!selectedIhmLanguage) {
                    selectedIhmLanguage = 'fr_FR';
                }
                return selectedIhmLanguage;
            },

            getSelectedIhmLanguage: function () {
                return this.getSelectedIhmLocale().split('_')[0];
            },
            setSelectedIhmLocale: function (language) {
                webStorage.local.set('ihmLanguage', language);
            },
            getPreferedTreeSettings: function () {
                var treeSettings = webStorage.local.get('treeSettings');

                if (!treeSettings) {
                    treeSettings = {
                        universeId: undefined,
                        departmentId: undefined,
                        subDepartmentId: undefined,
                        familyId: undefined,
                    };
                } else {
                    treeSettings = angular.fromJson(treeSettings);
                }
                return treeSettings;
            },
            setUser: function (userToSet) {
                user = userToSet;
            },
            getUser: function () {
                return user.user;
            },
            getFullUser: function () {
                return user;
            },
            setPreferedTreeSettings: function (treeSettings) {
                webStorage.session.remove('treeSettings');
                webStorage.local.set('treeSettings', treeSettings);
                $rootScope.$broadcast('profileTreeChanged');
            },

            /** Synchronous call */
            authenticate: function () {
                var self = this;

                return axiosService
                    .get(envService.getAuthUser())
                    .then(function (response) {
                        if ($window.DD_RUM) {
                            $window.DD_RUM.setUserProperty('name', response.data.user.displayName);
                            $window.DD_RUM.setUserProperty('email', response.data.user.email);
                        }
                        return response.data;
                    })
                    .then(function (oxitUserDetails) {
                        if (oxitUserDetails.user) {
                            $window.ga(
                                'set',
                                'userId',
                                oxitUserDetails.user.uid
                            ); // Définir l'ID utilisateur à partir du paramètre user_id de l'utilisateur connecté.
                        }

                        self.setUser(oxitUserDetails);
                        self.setBrandsUser();
                        $rootScope.logged = true;
                        self.checkWorkingLocale();
                        $rootScope.$broadcast('auth');

                        return self.getUserProperties();
                    })
                    .catch(() => {
                        $rootScope.logged = false;
                        $rootScope.$broadcast('auth_required');
                    });
            },
            getBrandByLdapKeys: function (list) {
                return axiosService
                    .post(envService.getUrlBrandLdap(), angular.toJson(list))
                    .then(function (response) {
                        return response.data;
                    });
            },

            setBrandsUser: function () {
                let self = this;
                self.getUser().brands = [];

                if (
                    this.getUser().additionalsProperties.applicationdomain &&
                    this.getUser().additionalsProperties.applicationdomain
                        .length !== 0
                ) {
                    let listLdapKeysBrand =
                        this.getUser().additionalsProperties.applicationdomain.map(
                            function (appDomain) {
                                return appDomain.split(',')[0].split('=')[1];
                            }
                        );
                    this.getBrandByLdapKeys(listLdapKeysBrand).then(function (
                        gotBrands
                    ) {
                        self.getUser().brands = gotBrands;
                    });
                }
            },

            changeUser: function (uid) {
                user.username = uid;
                user.user.uid = uid;
            },

            hasAuthority: function (authority) {
                if (!this.getFullUser() || !this.getFullUser().authorities) {
                    return false;
                }

                return (
                    this.getFullUser().authorities.find(function (authority1) {
                        return authority1.authority === authority;
                    }) != null
                );
            },

            isFranchisees: function () {
                return (
                    this.getFullUser().authorities.find(function (authority) {
                        return authority.authority === 'ROLE_CUDF';
                    }) != null
                );
            },

            hasRole: function (expectedRole, expectedCountry) {
                const country =
                    expectedCountry ||
                    $stateParams.country ||
                    this.getSelectedWorkingCountry();
                const tradRole = 'ROLE_TRAD_' + country;
                const countryInMyIdentity = user.user.country.toUpperCase();
                let hasExpectedRole = false;
                let hasRoleCudInt = false;

                for (const authority of this.getFullUser().authorities) {
                    if (
                        authority.authority === 'ROLE_ADMIN' ||
                        (expectedRole === 'ROLE_CUD' &&
                            authority.authority === tradRole)
                    ) {
                        return true;
                    }
                    hasExpectedRole =
                        hasExpectedRole || authority.authority === expectedRole;
                    hasRoleCudInt =
                        hasRoleCudInt || authority.authority === 'ROLE_CUD_INT';
                }

                if (country === 'INT') {
                    return (
                        hasRoleCudInt ||
                        (hasExpectedRole && countryInMyIdentity === 'FR')
                    );
                } else {
                    return hasExpectedRole && countryInMyIdentity === country;
                }
            },
        };
    }
})();
