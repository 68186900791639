(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .controller('ColorsFragranceFlavorsPopup', ColorsFragranceFlavorsPopup);


    ColorsFragranceFlavorsPopup.$inject = ['$scope', 'model', 'definitionCategory', 'siblings', 'colorsRepository', 'flavors', 'fragrances', 'colorIsDisabled', 'modelBrand', '$uibModalInstance'];

    function ColorsFragranceFlavorsPopup($scope, model, definitionCategory, siblings, colorsRepository, flavors, fragrances, colorIsDisabled, modelBrand, $uibModalInstance) {
        $scope.colorsRepository = colorsRepository;
        $scope.flavors = flavors;
        $scope.fragrances = fragrances;
        $scope.siblings = siblings;
        $scope.model = model;
        $scope.colorIsDisabled = colorIsDisabled;
        $scope.modelBrand = modelBrand;
        $scope.modelIsAmi = !$scope.modelBrand.brand || !$scope.modelBrand.brand.passion;
        $scope.errors = [];

        let isEmpty = function (myModel) {
            return myModel.flavors.flavor1Id === null &&
                myModel.flavors.flavor2Id === null &&
                myModel.flavors.flavor3Id === null &&
                myModel.fragrance === null &&
                myModel.colors.color1Id === null &&
                myModel.colors.color2Id === null &&
                myModel.colors.color3Id === null;
        };
        let isDefinitionCategoryEditable = function () {
            return _($scope.siblings)
                .chain()
                .filter(function (sibling) {
                    return sibling.available && sibling.code !== $scope.model.code;
                })
                .every(function (sibling) {
                    return isEmpty(sibling);
                })
                .value()
                &&
                isEmpty($scope.model);
        };


        $scope.checkAndClose = function (updatedModel) {
            if ($scope.errors.length === 0) {
                $uibModalInstance.close(updatedModel);
            }
        };

        let verifyDefinitionCategoryDuplication = function (updatedModel) {
            $scope.errors = [];
            const isEqualOrNull = (id1, id2) => id1 === id2 || (id1 == null && id2 == null);

            const hasSameColor = function (sibling) {
                // Early return if sibling is null or 'COLOR' is not the definition category
                if (!sibling || updatedModel.definitionCategory !== 'COLOR') {
                    return false;
                }
                // Compare each ID
                return (
                    updatedModel.colors && sibling.colors &&
                    isEqualOrNull(updatedModel.colors.color1Id, sibling.colors.color1Id) &&
                    isEqualOrNull(updatedModel.colors.color2Id, sibling.colors.color2Id) &&
                    isEqualOrNull(updatedModel.colors.color3Id, sibling.colors.color3Id)
                );
            };

            const hasSameFlavor = function (sibling) {
                // Early return if sibling is falsy or 'FLAVOR' is not the definition category
                if (!sibling || updatedModel.definitionCategory !== 'FLAVOR') {
                    return false;
                }
                // Compare each ID
                return (
                    updatedModel.flavors && sibling.flavors &&
                    isEqualOrNull(updatedModel.flavors.flavor1Id, sibling.flavors.flavor1Id) &&
                    isEqualOrNull(updatedModel.flavors.flavor2Id, sibling.flavors.flavor2Id) &&
                    isEqualOrNull(updatedModel.flavors.flavor3Id, sibling.flavors.flavor3Id)
                );
            };

            const hasSameFragrance = function (sibling) {
                return sibling && updatedModel.definitionCategory === 'FRAGRANCE' && sibling.fragrance === updatedModel.fragrance;
            };

            let siblingsWithDuplicatedDefinitionCategory = _($scope.siblings)
                .chain()
                .filter(sibling => sibling.available && sibling.code !== updatedModel.code)
                .filter(sibling => hasSameColor(sibling) || hasSameFlavor(sibling) || hasSameFragrance(sibling))
                .map(sibling => sibling.code)
                .value();
            if (siblingsWithDuplicatedDefinitionCategory.length) {
                siblingsWithDuplicatedDefinitionCategory.forEach(function (code) {
                    $scope.errors.push(code);
                });
            }
        };

        $scope.definitionCategoryChoosable = isDefinitionCategoryEditable();
        $scope.model.definitionCategory = definitionCategory;
        $scope.selectedDefinitionCategoryTab = $scope.model.definitionCategory !== null ? ['COLOR', 'FLAVOR', 'FRAGRANCE'].indexOf($scope.model.definitionCategory) : 0;

        $scope.changeSelectedDefinitionCategory = function (newDef) {
            $scope.model.definitionCategory = newDef;
        };

        let definitionCategoryUpdatedListener = function () {
            $scope.definitionCategoryChoosable = isDefinitionCategoryEditable();
            verifyDefinitionCategoryDuplication($scope.model);
        };

        definitionCategoryUpdatedListener();

        $scope.$watch('model.colors.color1Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.colors.color2Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.colors.color3Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.flavors.flavor1Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.flavors.flavor2Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.flavors.flavor3Id', definitionCategoryUpdatedListener);
        $scope.$watch('model.fragrance', definitionCategoryUpdatedListener);

    }
})();
