(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('superModelService', superModelService);

    superModelService.$inject = ['axiosService', 'envService', 'userSettings'];

    function superModelService(axiosService, envService, userSettings) {

        return {
            getSuperModelFamilies: function (superModelId, userLanguage) {
                return axiosService.get(
                    envService.getSuperModelsContext() +
                        '/' +
                        superModelId +
                        '/families',
                    {
                        params: { userLanguage },
                        data: {},
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            saveSports: function (smSeasonId, modelSportsGroups, superModelLocale) {
                return axiosService.post(
                    envService.getSuperModelSeasonsContext() +
                        '/' +
                        smSeasonId +
                        '/sports?superModelLocale=' +
                        superModelLocale,
                        modelSportsGroups,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );
            },
            getSuperModelEvents: function (code) {
                return axiosService.get(
                    envService.getSuperModelSeasonEventsContext() +
                        '/superModel' +
                        '/' +
                        code,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            search: function (criteria) {
                return axiosService.get(
                    envService.getSuperModelsContext(),
                    {
                        params: criteria,
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
            },
            saveSuperModelSeasonEvent: function (smSeasonId, smLocale, eventType, modelCodes) {
                return axiosService.post(
                    envService.getSuperModelSeasonEventsContext(),
                    {
                            smSeasonId: smSeasonId,
                            eventType: eventType,
                            createUserId: userSettings.getUser().uid,
                            createUserLocale:
                                userSettings.getSelectedWorkingLocale(),
                            createUserJobName: userSettings.getUser().jobName,
                            superModelLocale: smLocale,
                            modelCodes: modelCodes,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            Accept: 'application/json;charset=UTF-8',
                        },
                    }
                );
            },
            getSuperModelSeasonInfo: function (smSeasonId, locale) {
                return axiosService.get(
                    envService.getProductCardContext() +
                        '/' +
                        smSeasonId +
                        '/' +
                        locale,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            getSuperModelVersion: function (smCode, versionDate, locale) {
                return axiosService.get(
                    envService.getProductCardContext() + '/' + smCode,
                    {
                        params: { versionDate, locale },
                        withCredentials: true,
                        data: {},
                        headers: {
                            'Accept': 'application/json'
                        }
                    });
            },
            saveProductCard: function (productCard, smCode, versionDate, locale, userLocale) {
                return axiosService.put(
                    envService.getProductCardContext(),
                        productCard,
                    {
                        params: {
                            smCode,
                            userLocale,
                            versionDate,
                            locale,
                            datetime: Date.now()
                        },
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            getCurrentModelSeasons: function (smId, versionDate, locale) {
                return axiosService.get(
                    envService.getSuperModelsContext() + '/' + smId + '/currentSeason',
                    {
                        params: { versionDate, language: locale },
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            getAllAvailableLocales: function (smCode, versionDate) {
                return axiosService.get(
                    envService.getProductCardContext() +
                        '/' +
                        smCode +
                        '/' +
                        versionDate +
                        '/availableLocales',
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            getSuperModelsSeasonBySmId: function (smVersionId, language, country) {
                return axiosService.get(
                    envService.getSuperModelsContext() +
                        '/' +
                        smVersionId +
                        '/superModelSeason',
                    {
                        params: { locale: language + '_' + country },
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                        },
                    }
                );
            },
            saveSuperModel: function (superModel, locale) {
                return axiosService.put(
                    envService.getSuperModelsContext() +
                        '/' +
                        superModel.smSeasonId +
                        '?locale=' +
                        locale,
                        superModel,
                    {
                        withCredentials: true,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }
                );
            },
            createSuperModelVersion: function (mapVersions, user, locale) {
                return axiosService.post(
                    envService.getSuperModelsContext() + '/createVersion',
                    {
                            mapVersions: mapVersions,
                            userId: user.uid,
                            userLocale: locale,
                            userJob: user.jobName,
                    },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json;charset=UTF-8',
                            Accept: 'application/json',
                        },
                    }
                );
            },
            editProductNature: function (smCode, productNatureId) {
                return axiosService
                    .put(
                        envService
                            .getSuperModelModifyProductNatureURL()
                            .replace('{smCode}', smCode),
                            productNatureId,
                        {
                            withCredentials: true,
                            cache: false,
                            headers: {
                                Accept: 'application/json;charset=UTF-8',
                                'Content-Type':
                                    'application/json;charset=UTF-8',
                            },
                        }
                    )
                    .then(function (data) {
                        return data.data;
                    });
            }
        };

    }

})();
