(function () {
    'use strict';

    angular
        .module('spidwebApp')
        .factory('axiosService', axiosService);

    axiosService.$inject = [
        '$window',
        '$rootScope',
        '$timeout',
        '$injector',
        'envService'
    ];

    function axiosService(
        $window,
        $rootScope,
        $timeout,
        $injector,
        envService
    ) {
        //region Spinner interceptor
        $rootScope.nbPendingRequests = 0;
        const noSpinnerNeeded = [
            envService.getCartContent()
        ];

        function needSpinner(url) {
            return !url.endsWith('.html') && !noSpinnerNeeded.some(function (u) {
                return url.endsWith(u);
            });
        }

        //endregion

        //region Application loaded interceptor
        let applicationLoaded = false;
        let requestsCounter = 0;
        let responsesCounter = 0;
        let applicationSeemsToBeLoaded = null;
        const doNotCountIt = [
            'api.jspz'
        ];

        function shouldAvoid(url) {
            return doNotCountIt.indexOf(url) > -1;
        }

        function broadcastApplicationLoading() {
            $rootScope.$broadcast('application_loading', {
                requests: requestsCounter,
                responses: responsesCounter
            });
        }

        function broadcastApplicationLoadedIfNeeded() {
            if (requestsCounter - responsesCounter < 1) {
                applicationSeemsToBeLoaded = $timeout(function () {
                    applicationLoaded = true;
                    $rootScope.$broadcast('application_loaded');
                }, 10);
            }
        }

        //endregion

        //region Error interceptor
        let $uibModal = null;
        let errorModal = null;
        let errors = [];
        const doNotDisplayErrorOnStatuses = [
            401,
            404,
            406,
            409,
        ];

        function displayError(error) {
            errors.push(error);
            if (!errorModal) {
                errorModal = $uibModal.open({
                    templateUrl: 'dialogs/error/error.html',
                    controller: 'ErrorController',
                    size: 'xl',
                    resolve: {
                        errors: function () {
                            return errors;
                        }
                    }
                });
                errorModal.closed.then(function () {
                    errors = [];
                    errorModal = null;
                });
            }
        }

        //endregion

        //region Axios interceptors
        function requestInterceptor(config) {
            //region Spinner interceptor
            if (needSpinner(config.url)) {
                $rootScope.nbPendingRequests++;
            }
            //endregion

            //region Application loaded interceptor
            if (!shouldAvoid(config.url) && !applicationLoaded) {
                requestsCounter++;

                if (applicationSeemsToBeLoaded) {
                    $timeout.cancel(applicationSeemsToBeLoaded);
                }

                broadcastApplicationLoading();
            }
            //endregion
            $rootScope.$apply();
            return config;
        }

        function responseInterceptor(response) {
            //region Spinner interceptor
            if (response && response.config && needSpinner(response.config.url)) {
                $rootScope.nbPendingRequests--;
            }
            //endregion

            //region Application loaded interceptor
            if (!shouldAvoid(response.config.url) && !applicationLoaded) {
                responsesCounter++;

                broadcastApplicationLoading();

                broadcastApplicationLoadedIfNeeded();
            }
            //endregion
            $rootScope.$apply();
            return response;
        }

        function responseErrorInterceptor(error) {
            const url = error.config && error.config.url ? error.config.url : null;
            const status = error.response && error.response.status ? error.response.status : null;

            //region Spinner interceptor
            if (url && needSpinner(url)) {
                $rootScope.nbPendingRequests--;
            }
            //endregion

            //region Application loaded interceptor
            if (url && !shouldAvoid(url) && !applicationLoaded) {
                responsesCounter++;

                broadcastApplicationLoading();

                broadcastApplicationLoadedIfNeeded();
            }
            //endregion

            //region Error interceptor
            $uibModal = $uibModal || $injector.get('$uibModal');

            if (status === 401 && url.indexOf(envService.getBaseUrl()) > -1) {
                $rootScope.$broadcast('token_expired');
                return Promise.reject(error); // Do not display error modal for 401 on API request
            }

            if (status === -1 && url && url.indexOf(envService.getCartContent()) > -1) {
                return Promise.reject(error); // Do not display error modal for 401 on cart content request
            }

            if (doNotDisplayErrorOnStatuses.indexOf(status) === -1 || !error.config) {
                displayError(error);
            }
            //endregion
            $rootScope.$apply();
            return Promise.reject(error);
        }

        //endregion

        const instance = $window.axios.create({
            baseURL: envService.getBaseUrl(),
            headers: {
                'x-api-key': envService.properties[envService.getCurrentEnvironment()].apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            withCredentials: true,
            mode: 'no-cors'
        });

        instance.interceptors.request.use(requestInterceptor);
        instance.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

        return {
            get: (url, params) => instance.get(url, params),
            post: (url, data, params) => instance.post(url, data, params),
            put: (url, data, params) => instance.put(url, data, params),
            delete: (url, data) => instance.delete(url, data),
            head: (url, params) => instance.head(url, params),
            instance
        };
    }
})();
