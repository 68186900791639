(function () {
    'use strict';

    function AuthProvider(options, $window) {
        const axiosService = options.axiosService;
        const authAuthorizeUrl = options.authorizeUrl;
        const authTokensUrl = options.authTokensUrl;
        const refreshTokensUrl = options.refreshTokensUrl;
        const userAuth = options.userAuth;

        function loginRedirect() {
            sessionStorage.setItem('lastVisitedUrl', $window.location.href);
            $window.location.href = authAuthorizeUrl;
        }

        function handleCallback() {
            const params = new URLSearchParams($window.location.search);
            const error = params.get('error');
            const code = params.get('code');
            const state = params.get('state');

            if (error) {
                return;
            }

            if (!code || !state) {
                loginRedirect();
                return;
            }

            axiosService.post(`${authTokensUrl}?code=${code}&state=${state}`)
                .then(response => {
                    if (response.status === 200) {
                        let {delayBeforeRefreshToken, refreshTokenIssuedAt} = response.data;
                        localStorage.setItem('token_exchange_completed', 'true');
                        localStorage.setItem('refresh_token_in', delayBeforeRefreshToken);
                        localStorage.setItem('refresh_token_issued_at', refreshTokenIssuedAt);
                        scheduleRefreshToken();
                        const lastUri = sessionStorage.getItem('lastVisitedUrl');
                        console.warn(`Redirection to last visited url: ${lastUri}`);
                        $window.location.replace(lastUri ? lastUri : '/');
                    } else {
                        console.error(`Token exchange failed with status: ${response.status}`);
                        loginRedirect();
                    }
                })
                .catch(() => {
                    loginRedirect();
                });
        }

        function checkSession() {
            return axiosService.get(userAuth)
                .then(response => response.status === 200)
                .catch(() => false);
        }


        function init() {
            const params = new URLSearchParams($window.location.search);
            const code = params.get('code');
            const tokenExchangeCompleted = localStorage.getItem('token_exchange_completed');

            if (code && !tokenExchangeCompleted) {
                handleCallback();
            } else {
                localStorage.removeItem('token_exchange_completed');
                checkSession()
                    .then(isAuthenticated => {
                        if (!isAuthenticated) {
                            loginRedirect();
                        }
                    })
                    .catch(error => {
                        console.error('Error checking session:', error);
                        loginRedirect();
                    });
            }
        }

        function scheduleRefreshToken() {
            let refreshTokenTimer = localStorage.getItem('refresh_token_in') || 300000;
            $window.setInterval(() => {
                console.log(`RefreshTokenTimer: refresh token in: ${refreshTokenTimer}`);
                refreshToken();
            }, refreshTokenTimer);
        }

        function refreshToken() {
            let refreshTokenIssuedAt = localStorage.getItem('refresh_token_issued_at');
            if (refreshTokenIssuedAt) {
                return axiosService.post(refreshTokensUrl, {refreshTokenIssuedAt: refreshTokenIssuedAt})
                    .then(response => {
                        if (response.status === 200) {
                            localStorage.setItem('refresh_token_in', response.data.delayBeforeRefreshToken);
                            scheduleRefreshToken();
                        }
                        return response.data;
                    })
                    .catch(() => {
                        localStorage.removeItem('refresh_token_issued_at');
                    });
            } else {
                return Promise.resolve(() => handleCallback());
            }
        }

        init();

        return {refreshToken: () => refreshToken()};
    }

    angular.module('spidwebApp').factory('authService', authService);

    authService.$inject = ['envService', 'axiosService', '$window', '$timeout'];

    function authService(envService, axiosService, $window) {
        return AuthProvider({
            axiosService: axiosService,
            authorizeUrl: envService.getAuthorizeUrl(),
            authTokensUrl: envService.getAuthTokensUrl(),
            refreshTokensUrl: envService.getRefreshTokensUrl(),
            userAuth: envService.getAuthUser(),
        }, $window);
    }
})();

